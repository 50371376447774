import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Sidebar from "../dashboard/sidebar";
import Header from "../dashboard/header";
import Swal from "sweetalert2";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

const QuestionPage = () => {
    const { fileName } = useParams();
    const [questions, setQuestions] = useState([]);
    const [userAnswers, setUserAnswers] = useState({});
    const [isExamStarted, setIsExamStarted] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const questionsPerPage = 3;
    const [fullName, setFullName] = useState(""); // State for full name

    const [timer, setTimer] = useState(0); // Timer in seconds, will be fetched from the DB
    const [timerActive, setTimerActive] = useState(false); // To start/stop the timer

    // Fetch user's full name from localStorage on component mount
    useEffect(() => {
        const storedFullName = localStorage.getItem("fullName"); // Retrieve full name from localStorage
        if (storedFullName) {
            setFullName(storedFullName);
        }
    }, []);

    const userName = fullName || "John Doe"; // Display full name or default if not available
    const courseName = fileName;

    const shuffleQuestions = (questions, userSeed) => {
        console.log("Before Shuffle:", questions); // Log before shuffle

        // Generate a deterministic seed-based random function
        const seededRandom = (seed) => {
            let x = Math.sin(seed++) * 10000;
            return x - Math.floor(x);
        };

        // Convert userSeed (e.g., userName) to a numeric seed
        const seed = userSeed
            .split("")
            .reduce((acc, char) => acc + char.charCodeAt(0), 0);

        // Fisher-Yates Shuffle with seeded random
        for (let i = questions.length - 1; i > 0; i--) {
            const j = Math.floor(seededRandom(seed + i) * (i + 1)); // Generate random index based on seed
            [questions[i], questions[j]] = [questions[j], questions[i]];
        }

        console.log("After Shuffle:", questions); // Log after shuffle
        return questions;
    };





    // Fetch questions and timer from the server
    useEffect(() => {
        fetch(
            `https://swiftsendify.com/learning_api/getQuestions.php?file_name=${fileName}`
        )
            .then(response => response.json())
            .then(data => {
                console.log("Fetched Questions:", data.questions); // Debugging
                let shuffled = shuffleQuestions([...data.questions]);
                console.log("Shuffled Questions:", shuffled); // Debugging
                setQuestions([]); // Force React to recognize change
                setTimeout(() => setQuestions([...shuffled]), 100); // Delay to force update
            })
            .catch(error => console.error("Error fetching questions:", error));
    }, []);



    // Timer logic - starts when the exam starts
    useEffect(() => {
        let timerInterval;
        if (timerActive) {
            timerInterval = setInterval(() => {
                setTimer((prevTime) => {
                    if (prevTime <= 0) {
                        clearInterval(timerInterval);
                        setTimerActive(false); // Stop the timer

                        // First warning
                        Swal.fire({
                            icon: 'warning',
                            title: 'Time is Up!',
                            text: 'Please submit your work or contact the Administrator for proper submission.',
                            confirmButtonText: 'Okay',
                        });

                        // Second warning after 20 seconds
                        setTimeout(() => {
                            Swal.fire({
                                icon: 'error',
                                title: 'Final Warning!',
                                text: 'All your scores will be set to 0 automatically if you do not go to the last page to submit.',
                                confirmButtonText: 'Understood',
                            });

                            // Third warning after an additional 20 seconds (total 40 seconds)
                            setTimeout(() => {
                                Swal.fire({
                                    icon: 'info',
                                    title: 'Action Required!',
                                    text: 'Please go to the last page of the questions and submit your work now!',
                                    confirmButtonText: 'Go to Last Page',
                                });
                            }, 20000); // 20 seconds after the second warning
                        }, 20000); // 20 seconds after the first warning

                        return 0; // Ensure timer doesn't go negative
                    } else {
                        return prevTime - 1;
                    }
                });
            }, 1000);
        } else {
            clearInterval(timerInterval);
        }

        return () => clearInterval(timerInterval); // Cleanup interval
    }, [timerActive]);





    // Format time as mm:ss
    const formatTime = (timeInSeconds) => {
        const minutes = Math.floor(timeInSeconds / 60);
        const seconds = timeInSeconds % 60;
        return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
    };

    // Calculate indices for pagination
    const indexOfLastQuestion = currentPage * questionsPerPage;
    const indexOfFirstQuestion = indexOfLastQuestion - questionsPerPage;
    const currentQuestions = questions.slice(indexOfFirstQuestion, indexOfLastQuestion);

    // Handle answer selection
    const handleAnswerChange = (questionId, answer) => {
        setUserAnswers((prevAnswers) => ({
            ...prevAnswers,
            [questionId]: answer,
        }));
    };

    // Pagination handlers
    const nextPage = () => {
        if (indexOfLastQuestion < questions.length) {
            setCurrentPage(currentPage + 1);
        }
    };

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    // Calculate score
    const calculateScore = () => {
        let score = 0;
        questions.forEach((question) => {
            if (
                userAnswers[question.id] &&
                userAnswers[question.id].toLowerCase() === question.correct_option.toLowerCase()
            ) {
                score++;
            }
        });
        return score;
    };

    // Submit results to the server
    const handleSubmit = async () => {
        if (isSubmitting) return;

        Swal.fire({
            title: "Are you sure?",
            text: "Do you want to submit the test? You won't be able to change your answers after submission.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, submit it!",
            cancelButtonText: "Cancel",
        }).then(async (result) => {
            if (result.isConfirmed) {
                setIsSubmitting(true);
                const score = calculateScore();

                try {
                    const response = await fetch("https://swiftsendify.com/learning_api/saveResult.php", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            user_name: userName,
                            course: courseName,
                            score: score,
                        }),
                    });

                    const data = await response.json();
                    if (data.status === "success") {
                        Swal.fire({
                            title: "Success!",
                            text: `Your score has been processed.`,
                            icon: "success",
                        }).then(() => {
                            window.location.reload(); // Reload the page after the alert is closed
                        });
                    } else {
                        Swal.fire({
                            title: "Error",
                            text: data.message || "Failed to save your result.",
                            icon: "error",
                        });
                    }
                } catch (error) {
                    Swal.fire({
                        title: "Error",
                        text: "Something went wrong. Please try again.",
                        icon: "error",
                    });
                } finally {
                    setIsSubmitting(false);
                }
            }
        });
    }

    const handleStartExam = async () => {
        try {
            const response = await fetch("https://swiftsendify.com/learning_api/checkExamTaken.php", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    user_name: userName,
                    course: courseName,
                }),
            });

            const data = await response.json();
            if (data.status === "taken") {
                Swal.fire({
                    title: "Exam Already Taken",
                    text: `You have already taken this exam.`,
                    icon: "warning",
                    confirmButtonColor: "#3085d6",
                    confirmButtonText: "Okay",
                });
            } else {
                Swal.fire({
                    title: "Are you sure you want to start the exam?",
                    text: "Once started, you cannot stop the exam!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, start it!",
                    cancelButtonText: "No, cancel!",
                }).then((result) => {
                    if (result.isConfirmed) {
                        setIsExamStarted(true);
                        setTimerActive(true); // Start the timer when exam starts
                    }
                });
            }
        } catch (error) {
            Swal.fire({
                title: "Error",
                text: "Something went wrong while checking the exam status. Please try again.",
                icon: "error",
            });
        }
    };

    useEffect(() => {
        const fetchQuestions = async () => {
            try {
                const response = await fetch(
                    `https://swiftsendify.com/learning_api/getQuestions.php?file_name=${fileName}`
                );
                const data = await response.json();

                console.log(data); // Log the data to check the response

                if (data.status === "success") {
                    setQuestions(data.questions);
                    setTimer(data.timer); // Assuming 'timer' field is returned from the database
                } else {
                    setError(data.message || "Failed to fetch questions.");
                }
            } catch (err) {
                setError("An error occurred while fetching questions.");
            } finally {
                setLoading(false);
            }
        };

        fetchQuestions();
    }, [fileName]);



    return (
        <div className="min-h-screen flex flex-col bg-gray-100">
            <div className="fixed top-0 left-0 right-0 z-20">
                <Header />
            </div>

            <div className="flex flex-1 pt-16 md:pt-20">
                <div className="hidden md:block w-64 bg-white shadow-lg z-10">
                    <Sidebar />
                </div>

                <div className="flex-1 p-4 md:p-6 lg:p-8 pt-20 md:pt-24">
                    <div className="bg-white shadow-md rounded-lg p-6">
                        <h2 className="text-2xl font-bold mb-4">Questions for {fileName}</h2>

                        {/* Greeting the user */}
                        <p className="text-xl">Welcome, {userName}!</p>

                        {/* Display the timer */}
                        {isExamStarted && (
                            <p className="text-xl text-red-600">Time remaining: {formatTime(timer)}</p>
                        )}

                        {loading && (
                            <div className="flex justify-center items-center">
                                <div className="spinner-border text-blue-500" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                        )}
                        {error && <p className="text-red-500">{error}</p>}

                        {!loading && !isExamStarted && !error && (
                            <button
                                onClick={handleStartExam}
                                className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 mb-4"
                            >
                                Start the Exam
                            </button>
                        )}

                        {isExamStarted && currentQuestions.length > 0 && (
                            <ul>
                                {currentQuestions.map((question, index) => (
                                    <li key={question.id} className="mb-4 text-left">
                                        <div className="font-bold">
                                            Question {indexOfFirstQuestion + index + 1}:{" "}
                                            {question.question_text}
                                        </div>
                                        <div className="mt-2">
                                            {["A", "B", "C", "D"].map((option) => (
                                                <div key={option}>
                                                    <label htmlFor={`option-${option}-${question.id}`}>
                                                        <input
                                                            id={`option-${option}-${question.id}`}
                                                            type="radio"
                                                            name={`question-${question.id}`}
                                                            value={question[`option_${option.toLowerCase()}`]}
                                                            onChange={() =>
                                                                handleAnswerChange(
                                                                    question.id,
                                                                    question[`option_${option.toLowerCase()}`]
                                                                )
                                                            }
                                                            checked={userAnswers[question.id] === question[`option_${option.toLowerCase()}`]}
                                                        />{" "}
                                                        {question[`option_${option.toLowerCase()}`]}
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        )}

                        <div className="flex justify-between mt-4">
                            <button
                                onClick={prevPage}
                                disabled={currentPage === 1}
                                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                            >
                                <FaArrowLeft />
                            </button>
                            {indexOfLastQuestion >= questions.length ? (
                                <button
                                    onClick={handleSubmit}
                                    className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
                                >
                                    Submit
                                </button>
                            ) : (
                                <button
                                    onClick={nextPage}
                                    disabled={indexOfLastQuestion >= questions.length}
                                    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                                >
                                    <FaArrowRight />
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default QuestionPage;
